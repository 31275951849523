import {APIResponse, Organization, OrganizationMember} from "../types";
import {useEffect, useState} from "react";
import i18n from "../i18n";
import {level3ApiEndpoint} from "../utils/apiEndpointUrl";
import {User} from "firebase/auth";
import styles from "../styles/Common.module.css";

export default function OrganizationMemberSetting(
  {
    currentUser,
    currentOrganization,
  }: {
    currentUser: User;
    currentOrganization: Organization;
  }) {
  const [organizationMember, setOrganizationMember] = useState<OrganizationMember>()

  useEffect(() => {
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(
          `${level3ApiEndpoint()}/organizationMembers/self?organizationId=${currentOrganization.id}`,
          {
            headers: {'Authorization': idToken},
          }
        )
          .then(async (response) => await response.json())
          .then((response: APIResponse<OrganizationMember>) => {
            setOrganizationMember(response.content)
          });
      })
      .catch(function (error) {
        console.log(error)
      });
  }, [])

  const onChangeOrganizationMemberSetting = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        const formData = new FormData();
        formData.set('email', String(organizationMember!.email));
        fetch(
          `${level3ApiEndpoint()}/organizationMembers/${organizationMember!.id}?tenantId=${currentOrganization.id}`,
          {
            headers: {'Authorization': idToken},
            method: 'PATCH',
            body: formData,
          }
        )
          .then(async (response) => await response.json())
          .then((response: APIResponse<OrganizationMember>) => {
            setOrganizationMember(response.content)
          });
      })
      .catch(function (error) {
        console.log(error)
      });
  }
  return (
    <div className="p-4 sm:p-6 lg:p-8 content-center w-full">
      <div className="px-4 sm:px-0">
        <h3
          className="text-base font-semibold leading-7 text-gray-900">{i18n.t('noun.organization_member_setting')}</h3>
        <p
          className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{i18n.t('explanation.organization_member')}</p>
      </div>
      <form>
        <div className="border-b border-gray-900/10 pb-12">
          <div className="sm:col-span-4 mt-5">
            <label htmlFor="organizationMemberName"
                   className="block text-sm font-medium leading-6 text-gray-900">
              Email
            </label>
            <div className="mt-2">
              <div
                className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  type="email"
                  name="organizationMemberEmail"
                  id="organizationMemberEmail"
                  className={styles.formInput}
                  onChange={(event) =>
                    setOrganizationMember({
                      ...organizationMember!,
                      email: event.target.value
                    })
                  }
                  value={organizationMember?.email}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button type="button" className={styles.undoButton}>
            {i18n.t('action.cancel')}
          </button>
          <button
            type="submit"
            className={styles.button}
            onClick={(event) => onChangeOrganizationMemberSetting(event)}
          >
            {i18n.t('action.save')}
          </button>
        </div>
      </form>
    </div>
  )
}
