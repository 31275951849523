import {useState} from 'react';
import {firebaseAuth} from '../firebaseAuth';
import {createUserWithEmailAndPassword} from "firebase/auth";
import {Grid} from '@mui/material';
import i18n from '../i18n';
import StorysetAnnotation from '../components/StorysetAnnotation';
import {Constants} from "../constants";
import I18n from "../i18n";
import Notification from "../components/Notification";
import styles from "../styles/Common.module.css";
import {level1ApiEndpoint} from "../utils/apiEndpointUrl";

export default function SignUp() {
  const [signUpForm, setSignUpForm] = useState(
    {
      email: {
        value: '',
        error: '',
      },
      password: {
        value: '',
        error: '',
      },
      passwordConfirmation: {
        value: '',
        error: '',
      },
    })

  const [openNotification, setOpenNotification] = useState(false);
  const [httpResponseResult, setHttpResponseResult] = useState({success: '', error: ''})

  const isValidSignUpForm = (form: typeof signUpForm): boolean => {
    let isValid = true
    if (!Constants.regex.email.test(form.email.value)) {
      form = structuredClone(form)
      form.email.error = I18n.t('error.invalid_email')
      setSignUpForm(form)
      isValid = false
    }
    if (!Constants.regex.password.test(form.password.value)) {
      form = structuredClone(form)
      form.password.error = I18n.t('error.invalid_password')
      setSignUpForm(form)
      isValid = false
    }
    if (form.password.value != form.passwordConfirmation.value) {
      form = structuredClone(form)
      form.passwordConfirmation.error = I18n.t('error.password_does_not_match')
      setSignUpForm(form)
      isValid = false
    }

    return isValid
  }

  const signUp = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidSignUpForm(signUpForm)) {
      return
    }

    createUserWithEmailAndPassword(firebaseAuth, signUpForm.email.value, signUpForm.password.value)
      .then((userCredential) => {
        userCredential.user
          .getIdToken(true)
          .then((idToken) => {
            fetch(
              `${level1ApiEndpoint()}/users`,
              {
                headers: {'Authorization': idToken},
                method: 'POST',
              }
            ).then(() => {
              const message = structuredClone(httpResponseResult)
              message.success = I18n.t('individual_pages.sign_up.successfully_created_user')
              setHttpResponseResult(message)
              window.location.replace('/create-initial-organization')
            })
          })
      })
      .catch((error) => {
        const message = structuredClone(httpResponseResult)
        message.error = error.message
        setHttpResponseResult(message)
      });
  }
  return (
    <Grid container>
      {httpResponseResult.error &&
          <Notification
              alertMessage={httpResponseResult.error}
              openNotification={openNotification}
              setOpenNotification={setOpenNotification}
          />
      }
      {httpResponseResult.success &&
          <Notification
              infoMessage={httpResponseResult.success}
              openNotification={openNotification}
              setOpenNotification={setOpenNotification}
          />
      }
      <Grid item md={5}>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 hidden md:block">
          <img src='/images/signup.png'></img>
          <StorysetAnnotation/>
        </div>
      </Grid>
      <Grid item md={7} className="w-full">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 md:py-12 lg:px-8">
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={signUp}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  {i18n.t('noun.email')}
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={signUpForm.email.value}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      setSignUpForm(Object.assign({}, signUpForm, {email: {value: e.target.value, error: ''}}))
                    }}
                    required
                    className={styles.formInput}
                  />
                  <p className="text-red-500 text-xs">{signUpForm.email.error}</p>
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    {i18n.t('noun.password')}
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    placeholder={I18n.t('individual_pages.sign_up.password_place_holder') || ''}
                    required
                    value={signUpForm.password.value}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      setSignUpForm(Object.assign({}, signUpForm, {password: {value: e.target.value, error: ''}}))
                    }}
                    className={styles.formInput}
                  />
                  <p className="text-red-500 text-xs">{signUpForm.password.error}</p>
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    {i18n.t('noun.password_confirmation')}
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    type="password"
                    required
                    value={signUpForm.passwordConfirmation.value}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      setSignUpForm(Object.assign({}, signUpForm, {
                        passwordConfirmation: {
                          value: e.target.value,
                          error: ''
                        }
                      }))
                    }}
                    className={styles.formInput}
                  />
                  <p className="text-red-500 text-xs">{signUpForm.passwordConfirmation.error}</p>
                </div>
              </div>
              <div className="text-xs text-gray-500">
                <p>{I18n.t('individual_pages.sign_up.agree_to_signup1')}</p>
                <p className="mt-2">{I18n.t('individual_pages.sign_up.agree_to_signup2')}
                  (<a
                    href="https://storage.googleapis.com/sigq-public-docs/%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84.pdf"
                    className="hover:underline" target="_blank">{I18n.t('noun.terms')}
                  </a>
                  /
                  <a
                    href="https://storage.googleapis.com/sigq-public-docs/%E3%83%95%E3%82%9A%E3%83%A9%E3%82%A4%E3%83%8F%E3%82%99%E3%82%B7%E3%83%BC%E3%83%9B%E3%82%9A%E3%83%AA%E3%82%B7%E3%83%BC.pdf"
                    className="hover:underline"
                    target="_blank"
                  >
                    {I18n.t('noun.privacy_policy')}
                  </a>
                  )
                </p>
                <p>{I18n.t('individual_pages.sign_up.agree_to_signup3')}</p>
              </div>
              <div>
                <button
                  className={`${styles.button} w-full`}
                  type="submit"
                >
                  {i18n.t('individual_pages.sign_up.signup_with_confirmation')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
