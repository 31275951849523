import {User} from "firebase/auth";
import {APIResponse, Organization, ResourceAccessHistory} from "../types";
import {level3ApiEndpoint} from "../utils/apiEndpointUrl";
import {useEffect, useState} from "react";
import i18n from "../i18n";
import PageNavigation from "../components/pageNavigation";
import {getUAStr} from "../utils/user_agent";
import {parseDate} from "../utils/date";

export default function AccessLogExplorer(
  {
    currentUser,
    currentOrganization
  }: {
    currentUser: User;
    currentOrganization: Organization;
  }) {
  const [histories, setHistories] = useState<ResourceAccessHistory[]>([])
  const [pageNumber, setPageNumber] = useState(1);
  const dataPerPage = 100
  const loadResourceAccessControl = () => {
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(
          `${level3ApiEndpoint()}/histories?organizationId=${currentOrganization.id}`,
          {
            headers: {'Authorization': idToken},
          }
        )
          .then(async (response) => await response.json())
          .then((response: APIResponse<ResourceAccessHistory[]>) => {
            setHistories(response.content)
          })
      })
      .catch(function (error) {
        // Handle error
      });
  }

  useEffect(() => {
    loadResourceAccessControl()
  }, [])

  // @ts-ignore
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mt-10">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {i18n.t('noun.access_log')}
          </h1>
        </div>
      </div>
      <div className="mt-2 sm:mt-0 sm:ml-16 sm:flex-none text-right pt-3 text-gray-400 font-light">
        {i18n.t('noun.timezone')}: {Intl.DateTimeFormat().resolvedOptions().timeZone}
      </div>
      <div className="mt-2 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {i18n.t('noun.resource')}
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {i18n.t('noun.accessed_at')}
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {i18n.t('noun.email')}
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {i18n.t('noun.ip')}
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  {i18n.t('noun.user_agent')}
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Country
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  City
                </th>
              </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
              {histories?.length > 0 && histories.slice((pageNumber * dataPerPage - dataPerPage), pageNumber * dataPerPage).map((history) => (
                <tr key={history.id}>
                  <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                    {history.resourceName}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                    {parseDate(history.accessedAt)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{history.accessMeta.email}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{history.accessMeta.ip}
                  </td>
                  <td
                    className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{getUAStr(history.accessMeta.userAgent)}
                  </td>
                  <td
                    className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{history.geoInfo.countryLong}
                  </td>
                  <td
                    className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{history.geoInfo.city}
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="my-10">
          <PageNavigation
            numPages={Math.ceil(histories?.length / dataPerPage)}
            setPage={setPageNumber}
          />
        </div>
      </div>
    </div>
  )
}
