import I18n from "../i18n";

export default function Footer() {
  return (
    <footer className="bg-white border-y-2 text-xs">
      <div className="max-w-screen-xl py-1 mx-auto md:py-3 md:px-6 p-2">
        <div>
          <div className="flex flex-wrap md:w-4/5 m-auto">
            <div className="w-1/2 lg:w-1/4 mb-4">
              <a
                href="https://lp.sigq.io"
                className="hover:underline"
                target="_blank">
                {I18n.t('noun.corporate_site')}
              </a>
            </div>
            <div className="w-1/2 lg:w-1/4 mb-4">
              <a
                href="https://storage.googleapis.com/sigq-public-docs/%E3%83%95%E3%82%9A%E3%83%A9%E3%82%A4%E3%83%8F%E3%82%99%E3%82%B7%E3%83%BC%E3%83%9B%E3%82%9A%E3%83%AA%E3%82%B7%E3%83%BC.pdf"
                className="hover:underline"
                target="_blank"
              >
                {I18n.t('noun.privacy_policy')}
              </a>
            </div>
            <div className="w-1/2 lg:w-1/4 mb-4">
              <a href="https://storage.googleapis.com/sigq-public-docs/%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84.pdf"
                 className="hover:underline"
                 target="_blank">
                {I18n.t('noun.terms')}
              </a>
            </div>
            <div className="w-1/2 lg:w-1/4 mb-4">
              <a
                href="https://storage.googleapis.com/sigq-public-docs/%E7%89%B9%E5%AE%9A%E5%95%86%E5%8F%96%E5%BC%95%E6%B3%95%E3%81%AB%E5%9F%BA%E3%81%A4%E3%82%99%E3%81%8F%E8%A1%A8%E8%A8%98.pdf"
                className="hover:underline"
                target="_blank">
                {I18n.t('noun.specified_commercial_transactions')}
              </a>
            </div>
          </div>
          <p className="block text-sm text-center text-gray-500 mt-3">© 2023 SIGQ. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
