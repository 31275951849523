import {APIResponse, Organization, OrganizationMember} from "../types";
import {useLayoutEffect, useState} from "react";
import {parseDate} from "../utils/date";
import {ExclamationTriangleIcon} from "@heroicons/react/24/solid";
import I18n from "../i18n";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {level2ApiEndpoint} from "../utils/apiEndpointUrl";
import {User} from "firebase/auth";
import styles from "../styles/Common.module.css";

const tabs = [
  {id: 'organization', name: I18n.t('noun.organization_setting'), current: true},
  {id: 'organizationMembers', name: I18n.t('noun.admin_organization_member_setting'), current: false},
  {id: 'plan', name: I18n.t('noun.plan'), current: false},
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function OrganizationSetting(
  {
    currentUser,
    currentOrganization,
  }: {
    currentUser: User;
    currentOrganization: Organization;
  }) {
  type UserSetting = {
    email: string;
  }

  type OrganizationSetting = {
    name: string;
  }
  const [userSetting, setUserSetting] = useState<UserSetting>(
    {email: currentUser.email || ''}
  )
  const [organizationSetting, setOrganizationSetting] = useState<OrganizationSetting>(
    {name: currentOrganization.name || ''}
  )

  const [organizationMembers, setOrganizationMembers] = useState<OrganizationMember[]>([])
  const [currentTabId, setCurrentTabId] = useState(new URL(window.location.href).searchParams.get('currentTabId') || tabs[0].id)
  const onChangeTab = (tabId: string) => {
    const url = new URL(window.location.href);
    url.searchParams.delete('currentTabId');
    url.searchParams.set('currentTabId', tabId);
    window.history.pushState({}, '', url.toString());
    setCurrentTabId(tabId)
  }

  useLayoutEffect(() => {
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(
          `${level2ApiEndpoint()}/organizations/${currentOrganization.id}`,
          {
            headers: {'Authorization': idToken},
          }
        )
          .then(async (response) => await response.json())
          .then((response: APIResponse<Organization>) => {
            console.log(response.content)
            setOrganizationMembers(response.content.organizationMembers)
          });
      })
      .catch(function (error) {
        console.log(error)
      });
  }, [currentUser])

  return (
    <div className="p-4 sm:p-6 lg:p-8 content-center w-full">
      <div className="border-b border-gray-200 pb-5 sm:pb-0">
        <div className="mt-3 sm:mt-4">
          <div>
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  className={classNames(
                    tab.id === currentTabId
                      ? styles.selectTabSelected
                      : styles.selectTabNotSelected,
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                  onClick={() => {
                    onChangeTab(tab.id)
                  }}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {currentTabId === "organization" &&
          <div>
              <form>
                  <div className="border-b border-gray-900/10 pb-12">
                      <p className="mt-1 text-sm leading-6 text-gray-600">
                        {I18n.t('explanation.organization_setting')}
                      </p>

                      <div className="sm:col-span-4 mt-5">
                          <label htmlFor="organizationName"
                                 className="block text-sm font-medium leading-6 text-gray-900">
                            {I18n.t('noun.organization')}
                          </label>
                          <div className="mt-2">
                              <div
                                  className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                                  <input
                                      type="text"
                                      name="organizationName"
                                      id="organizationName"
                                      autoComplete="organizationName"
                                      className={styles.formInput}
                                      value={organizationSetting.name}
                                      onChange={(event) =>
                                        setOrganizationSetting({
                                          ...organizationSetting,
                                          name: event.target.value
                                        })
                                      }
                                  />
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                      <button type="button" className={styles.undoButton}>
                        {I18n.t('action.cancel')}
                      </button>
                      <button
                          type="submit"
                          className={styles.button}
                          onClick={() => {
                            console.log(userSetting)
                          }}
                      >
                        {I18n.t('action.change')}
                      </button>
                  </div>
              </form>
          </div>
      }

      {currentTabId === "organizationMembers" &&
          <div>
              <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                      <p className="mt-2 text-sm text-gray-700">
                        {I18n.t('explanation.organization_member')}
                      </p>
                  </div>
              </div>
              <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                              <thead>
                              <tr>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {I18n.t('noun.email')}
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {I18n.t('noun.created_at')}
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {I18n.t('noun.updated_at')}
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {I18n.t('noun.role')}
                                  </th>
                              </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200">
                              {organizationMembers.map((organizationMember) => (
                                <tr key={organizationMember.id}>
                                  <td
                                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{organizationMember.email}</td>
                                  <td
                                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{parseDate(organizationMember.createdAt)}</td>
                                  <td
                                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{parseDate(organizationMember.updatedAt)}</td>
                                  <td
                                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{I18n.t(`common.${organizationMember.authority}`)}</td>
                                </tr>
                              ))}
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      }

      {currentTabId === "plan" &&
          <div className="bg-white">
              <div className="mx-auto max-w-7xl">
                  <div
                      className="mx-auto mt-10 max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
                      <div className="p-8 sm:p-10 lg:flex-auto">
                          <h3 className="text-2xl font-bold tracking-tight text-gray-900">{I18n.t('noun.team')}</h3>
                          <p className="text-base leading-7 text-gray-600">{I18n.t('noun.current_plan')}</p>
                          <p className="mt-6 text-base leading-7 text-gray-600">
                            {I18n.t('explanation.team_plan')}
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      }
    </div>
  )
}
