import {useState} from 'react';
import i18n from '../i18n';
import {Constants} from "../constants";
import I18n from "../i18n";
import styles from "../styles/Common.module.css";
import {level2ApiEndpoint} from "../utils/apiEndpointUrl";

export default function ResetPassword() {
  const [resetPasswordForm, setResetPasswordForm] = useState(
    {
      email: {
        value: '',
        error: '',
      }
    })

  const [httpResponseResult, setHttpResponseResult] = useState({success: '', error: ''})

  const isValidResetPasswordForm = (form: typeof resetPasswordForm): boolean => {
    let isValid = true
    if (!Constants.regex.email.test(form.email.value)) {
      form = structuredClone(form)
      form.email.error = I18n.t('error.invalid_email')
      setResetPasswordForm(form)
      isValid = false
    }

    return isValid
  }

  const resetPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidResetPasswordForm(resetPasswordForm)) {
      return
    }
    const formData = new FormData();
    formData.append('email', resetPasswordForm.email.value);
    fetch(`${level2ApiEndpoint()}/users/resetPassword`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    }).then((response) => {
      if (response.ok) {
        const message = structuredClone(httpResponseResult)
        message.success = I18n.t('individual_pages.sign_in.reset_password_email_sent')
        setHttpResponseResult(message)
      }
    })
  }
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 md:py-12 lg:px-8">
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={resetPassword}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              {i18n.t('noun.email')}
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={resetPasswordForm.email.value}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  setResetPasswordForm(Object.assign({}, resetPasswordForm, {
                    email: {
                      value: e.target.value,
                      error: ''
                    }
                  }))
                }}
                required
                className={styles.formInput}
              />
              <p className="text-red-500 text-xs mt-2">{resetPasswordForm.email.error}</p>
              <p className="text-blue-700 text-xs mt-2">{httpResponseResult.success}</p>
            </div>
          </div>
          <div>
            <button
              className={`${styles.button} w-full`}
              type="submit"
            >
              {i18n.t('action.post')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
