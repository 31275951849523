import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {en} from "./locales/en";
import {ja} from "./locales/ja";
import {sc} from "./locales/simplified_chinese";
import {spanish} from "./locales/spanish";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      ...en
    },
  },
  sc: {
    translation: {
      ...sc
    },
  },
  es: {
    translation: {
      ...spanish
    },
  },
  ja: {
    translation: {
      ...ja
    },
  },
  react: {
    wait: true,
  },
};

export const supportLanguages = ['en', 'ja', 'sc', 'es']
const currentLanguage = () => {
  let lang = localStorage.getItem("language");
  if (supportLanguages.includes(lang)) {
    return lang
  }
  if (navigator.language.startsWith('en')) {
    return 'en'
  } else if (navigator.language.startsWith('ja')) {
    return 'ja'
  } else if (navigator.language.startsWith('zh')) {
    return 'sc'
  } else if (navigator.language.startsWith('es')) {
    return 'es'
  } else {
    return 'en'
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: currentLanguage(),
    languages: supportLanguages,
    interpolation: {
      escapeValue: false,
    },
    load: 'current',
    fallbackLng: 'en',
  });

export default i18n;
