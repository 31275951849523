import i18n from "../i18n";
import styles from "../styles/Common.module.css"

const japanese = '日本語'
const simplified_chinese = '简体中文'
const spanish = 'Español'
export default function LanguageChangeSelectBox() {
  const changeLanguage = async (language: string) => {
    let lang = 'en'
    if (language === japanese) {
      lang = 'ja'
    }
    if (language === simplified_chinese) {
      lang = 'sc'
    }
    if (language === spanish) {
      lang = 'es'
    }
    await i18n.changeLanguage(lang)
    localStorage.setItem("language", lang);
    location.reload();
  }

  const languageOptions = () => {
    if (i18n.language === 'ja') {
      return japanese
    }
    if (i18n.language === 'sc') {
      return simplified_chinese
    }
    if (i18n.language === 'es') {
      return spanish
    } else {
      return 'English'
    }
  }

  return (
    <select
      id="language"
      name="language"
      className={styles.languageSelectBox}
      defaultValue={languageOptions()}
      onChange={async (event) => {
        await changeLanguage(event.target.value)
      }}
    >
      <option>English</option>
      <option>{simplified_chinese}</option>
      <option>{spanish}</option>
      <option>{japanese}</option>
    </select>
  )
}
