import {APIResponse, Organization, OrganizationMember} from "../types";
import {useEffect, useState} from "react";
import i18n from "../i18n";
import {User} from "firebase/auth";
import styles from "../styles/Common.module.css";
import LanguageChangeSelectBox from "../components/LanguageChangeSelectBox";

const tabs = [
  {id: 'user', name: i18n.t('noun.user_setting'), current: true},
  {id: 'environment', name: i18n.t('noun.environment_setting'), current: false},
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function UserSetting(
  {
    currentUser,
    currentOrganization,
  }: {
    currentUser: User;
    currentOrganization: Organization;
  }) {
  type UserSetting = {
    email: string;
  }
  const [currentTabId, setCurrentTabId] = useState(new URL(window.location.href).searchParams.get('currentTabId') || tabs[0].id)
  const onChangeTab = (tabId: string) => {
    const url = new URL(window.location.href);
    url.searchParams.delete('currentTabId');
    url.searchParams.set('currentTabId', tabId);
    window.history.pushState({}, '', url.toString());
    setCurrentTabId(tabId)
  }
  const [userSetting, setUserSetting] = useState<UserSetting>(
    {email: currentUser.email || ''}
  )

  return (
    <div className="p-4 sm:p-6 lg:p-8 content-center w-full">
      <div className="border-b border-gray-200 pb-5 sm:pb-0">
        <div className="mt-3 sm:mt-4">
          <div>
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  className={classNames(
                    tab.id === currentTabId
                      ? styles.selectTabSelected
                      : styles.selectTabNotSelected,
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                  onClick={() => {
                    onChangeTab(tab.id)
                  }}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {currentTabId === "user" &&
          <div>
              <div className="space-y-12">
                  <div className="border-b border-gray-900/10 pb-12">
                      <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                        {i18n.t('explanation.user_setting')}
                      </p>

                      <div className="sm:col-span-4 mt-5">
                          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                              Email
                          </label>
                          <div className="mt-2">
                              <div
                                  className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                  <input
                                      type="email"
                                      name="email"
                                      id="email"
                                      autoComplete="email"
                                      className={styles.formInput}
                                      value={userSetting.email}
                                      onChange={(event) =>
                                        setUserSetting({
                                          ...userSetting,
                                          email: event.target.value
                                        })
                                      }
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button type="button" className={styles.undoButton}>
                    {i18n.t('action.cancel')}
                  </button>
                  <button
                      type="submit"
                      className={styles.button}
                      onClick={() => {
                        console.log(userSetting)
                      }}
                  >
                    {i18n.t('action.save')}
                  </button>
              </div>
          </div>
      }
      {currentTabId === 'environment' &&
          <div className="mt-5 w-48">
              <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                {i18n.t('noun.language')}
              </label>
              <LanguageChangeSelectBox/>
          </div>
      }
    </div>
  )
}
