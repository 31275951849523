import {I18nLocales} from "./types";

export const spanish: I18nLocales = {
  action: {
    copy: 'Copiar',
    edit: 'Editar',
    cancel: 'Cancelar',
    cancel_edit: 'Cancelar edición',
    change: 'Cambiar',
    save_change: 'Guardar cambios',
    sign_out: 'Cerrar sesión',
    sign_in: 'Iniciar sesión',
    sign_up: 'Registrarse',
    delete: 'Eliminar',
    create: 'Crear',
    upload: 'Subir',
    download: 'Descargar',
    send: 'Enviar',
    save: 'Guardar',
    add: 'Añadir',
    share: 'Compartir',
    select: 'Seleccionar',
  },
  noun: {
    email: 'Dirección de correo electrónico',
    password: 'Contraseña',
    password_confirmation: 'Confirmación de contraseña',
    organization: 'Organización',
    organization_setting: 'Configuración de la organización',
    organization_member_setting: 'Configuración de miembros',
    access_count: 'Conteo de accesos',
    resource: 'Recurso',
    memo: 'Memo',
    expiration: 'Expiración',
    updated_at: 'Actualizado',
    created_at: 'Creado',
    accessed_at: 'Accedido',
    last_modified_by: 'Última modificación por',
    access_log: 'Registro de acceso',
    timezone: 'Zona horaria',
    ip: 'Dirección IP',
    location: 'Ubicación',
    user_agent: 'Agente de usuario',
    language: 'Idioma',
    admin: 'Administrador',
    role: 'Rol',
    user_setting: 'Configuración de usuario',
    uploaded_resources: 'Recursos subidos',
    sharing_resources: 'Recursos compartidos',
    access_log_explorer: 'Explorador de registros de acceso',
    accessible_emails: 'Correos electrónicos accesibles',
    operation_log: 'Registro de operaciones',
    environment_setting: 'Configuración del entorno',
    admin_organization_member_setting: 'Gestión de miembros de la organización',
    plan: 'Plan',
    team: 'Equipo',
    current_plan: 'Plan actual',
    operator: 'Operador',
    about_company: 'Acerca de la empresa',
    privacy_policy: 'Política de privacidad',
    terms: 'Términos de uso',
    specified_commercial_transactions: 'Divulgación del acto de transacciones comerciales especificadas',
    corporate_site: 'Sitio corporativo'
  },
  explanation: {
    supported_file_format: 'Extensiones soportadas: .pdf',
    user_setting: 'Las configuraciones de usuario son información utilizada en múltiples organizaciones cuando se pertenece a ellas con la misma información de usuario.',
    organization_member: 'Configuraciones para miembros utilizadas dentro de la organización actualmente mostrada.',
    organization_setting: 'Configuraciones para la organización actualmente mostrada.',
    team_plan: 'Todas las características están disponibles',
  },
  error: {
    invalid_email: 'Formato de correo electrónico no válido',
    invalid_password: 'Formato de contraseña no válido',
    contact_support: 'Ha ocurrido un error inesperado. Si el problema persiste, por favor contacte al soporte al cliente.',
    invalid_file: 'No se puede subir este archivo',
    password_does_not_match: 'Las contraseñas no coinciden',
    internal_server_error: 'Por favor, inténtelo de nuevo o contacte al soporte',
    invalid_request: 'Hay un error en el contenido enviado',
    user_not_found: 'Usuario no encontrado',
    wrong_password: 'Contraseña incorrecta',
    too_many_requests: 'Límite de solicitudes excedido. Por favor, inténtelo de nuevo más tarde.'
  },
  confirmation: {
    are_you_sure_to_delete: '¿Está seguro de que quiere eliminar?',
    are_you_sure_to_sign_out: '¿Está seguro de que quiere cerrar sesión?',
  },
  notification: {
    success_delete: 'Eliminado con éxito',
    copied_to_clipboard: 'Copiado al portapapeles'
  },
  state: {
    is_sharing: 'Compartiendo',
  },
  individual_pages: {
    receive: {
      new_resource_arrived: 'Ha llegado un archivo',
      messageBody: "Puede descargar el archivo desde este enlace hasta {{expireAt}}.",
      verify_email: 'Por favor, introduzca su dirección de correo electrónico.',
      verify_access_code: 'Por favor, introduzca el código de acceso.',
    },
    sign_in: {
      forget_password: 'Si olvidó su contraseña',
      not_a_member: '¿Todavía no tiene una cuenta?',
      reset_password_email_sent: 'Se ha enviado un correo electrónico para restablecer la contraseña. Por favor, revise su correo electrónico.'
    },
    sign_up: {
      signup_with_confirmation: 'Registrarse con acuerdo',
      agree_to_signup1: 'Al hacer clic en "Registrarse con acuerdo", usted acepta lo siguiente',
      agree_to_signup2: '・Leer y aceptar los Términos de Servicio y la Política de Privacidad',
      agree_to_signup3: '・Según sea necesario, podemos enviar anuncios, promociones y otras notificaciones por correo electrónico',
      successfully_created_user: 'Registro de usuario completado con éxito',
      password_place_holder: 'Al menos 8 caracteres, incluyendo letras mayúsculas y minúsculas y símbolos'
    },
    create_organization: {
      organization_member_name: 'Nombre del miembro en la organización',
      organization_member_email: 'Dirección de correo electrónico en la organización',
      organization_member_email_explanation1: 'Se utiliza para identificarte de manera única dentro de la organización.',
      organization_member_email_explanation2: 'La dirección de correo electrónico del usuario se utiliza para propósitos como el inicio de sesión y la autogestión del usuario, pero no se utiliza dentro de la organización.',
      create_user: 'Registro de usuario',
      create_office: 'Crear organización',
      go_to_dashboard: 'Ir al tablero',
      explanation1: 'Esta pantalla es para crear una organización',
      explanation2: 'El usuario creado en el Paso 1 puede pertenecer a múltiples organizaciones, y usted se convertirá en el administrador de la organización creada aquí.',
      explanation3: 'Las invitaciones a miembros a la organización creada aquí se pueden hacer desde la gestión de la organización dentro del tablero.',
    },
  }
}
