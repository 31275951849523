import React, {useEffect, useState} from 'react';
import PreviewModal from '../components/PreviewModal';
import i18n from '../i18n';
import {APIResponse, Organization} from '../types';
import {level1ApiEndpoint} from "../utils/apiEndpointUrl";
import {requestToBackendWithoutAuth, requestToBackendWithoutAuthAndGetFile} from "../utils/rpcLogics";
import styles from "../styles/Common.module.css";
import {parseUnixtimeToStr} from "../utils/date";
import LanguageChangeSelectBox from "../components/LanguageChangeSelectBox";

type CheckAvailability = {
  unavailableReason?: string;
  expireAt?: string
}

type CheckAuth = {
  method: "email" | "";
}

export default function Receive() {
  const queries = new URLSearchParams(window.location.search);
  const resourceAccessControlId = queries.get('id');
  const LOCAL_AUTH_KEY = `ts-auth-${resourceAccessControlId}`;

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [authPassed, setAuthPassed] = useState(false);
  const [pendingEmailConfirmation, setPendingEmailConfirmation] = useState(false);
  const [authEmail, setAuthEmail] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [expireAt, setExpireAt] = useState('');
  const [unavailableMessage, setUnavailableMessage] = useState('');
  const [previewUrl, setPreviewUrl] = useState('')
  const [authErrorMessage, setAuthErrorMessage] = useState('')
  const checkAvailability = async () => {
    if (!resourceAccessControlId) {
      return;
    }
    const [checkAvailability, error] = await requestToBackendWithoutAuth<CheckAvailability>(`${level1ApiEndpoint()}/receives/${resourceAccessControlId}/checkAvailability`, 'GET')
    if (!checkAvailability) {
      console.log(error)
      return
    }
    if (checkAvailability.unavailableReason) {
      setUnavailableMessage(checkAvailability.unavailableReason)
      return
    }
    if (checkAvailability.expireAt) {
      setExpireAt(checkAvailability.expireAt)
    }
    setIsLoading(false)
  };
  const checkAuth = () => {
    if (!resourceAccessControlId) {
      return;
    }
    fetch(
      `${level1ApiEndpoint()}/receives/${resourceAccessControlId}/checkAuth`
    )
      .then(async (response) => await response.json())
      .then((response: APIResponse<CheckAuth>) => {
        if (!response.content.method) {
          setAuthPassed(true);
          window.localStorage.setItem(LOCAL_AUTH_KEY, JSON.stringify(true));
        }
      });
    setIsLoading(false);
  };
  useEffect(() => {
    checkAvailability().then(() => {
      checkAuth();
    });
  }, []);

  const sendAccessCode = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!resourceAccessControlId) {
      return;
    }
    e.preventDefault();
    // do something
    const formData = new FormData();
    formData.append('email', authEmail);
    const [_, error] = await requestToBackendWithoutAuth<Response>(`${level1ApiEndpoint()}/receives/${resourceAccessControlId}/sendAccessCode`, 'POST', formData)
    if (error) {
      setAuthErrorMessage(`error.${error}`)
    } else {
      setPendingEmailConfirmation(true)
    }
  };

  const verifyAccessCode = (e: React.FormEvent<HTMLFormElement>) => {
    if (!resourceAccessControlId) {
      return;
    }
    e.preventDefault();
    // do something
    const formData = new FormData();
    formData.append('email', authEmail);
    formData.append('code', accessCode);
    fetch(`${level1ApiEndpoint()}/receives/${resourceAccessControlId}/auth`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    }).then((response) => {
      if (response.ok) {
        setPendingEmailConfirmation(false)
        setAuthPassed(true);
        window.localStorage.setItem(LOCAL_AUTH_KEY, JSON.stringify(true));
      } else if (response.status === 400) {
        setAuthErrorMessage(`${i18n.t('error.invalid_request')}`)
      } else if (response.status === 403) {
        setAuthErrorMessage(`${i18n.t('receive.error.access_code_not_match')}`)
      } else if (response.status === 500) {
        setAuthErrorMessage(`${i18n.t('error.internal_server_error')}`)
      }
    }).catch((err) => {
      console.log(err)
    });
  };

  const readStreamAndResponseUrl = async (response: Response) => {
    const body = response.body
    if (!body) {
      console.log("body is null")
      return
    }
    const reader = body.getReader();
    const blob = await new Response(new ReadableStream({
      start(controller) {
        return pump();

        function pump(): any {
          return reader.read().then(({done, value}) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            return pump();
          });
        }
      },
    })).blob()

    return URL.createObjectURL(blob)
  }

  const previewResource = async () => {
    if (!resourceAccessControlId) {
      return;
    }
    const formData = new FormData();

    if (authEmail) {
      formData.set('email', authEmail)
    }

    const [response, error] = await requestToBackendWithoutAuthAndGetFile<Response>(`${level1ApiEndpoint()}/receives/${resourceAccessControlId}/preview`, 'POST', formData)
    if (!response || error) {
      console.log(error)
      return
    }

    const url = await readStreamAndResponseUrl(response)
    if (!url) {
      console.log("failed to generate url")
      return
    }
    setPreviewUrl(url)
    setOpenPreviewModal(true);
  };

  return (
    <>
      <div className="min-h-full">
        <div className="bg-gray-800 pb-32">
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">
                {i18n.t('individual_pages.receive.new_resource_arrived')}
              </h1>
            </div>
          </header>
        </div>

        <main className="-mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8" style={{minHeight: "70vh"}}>
            <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
              {isLoading && (
                <div className="flex justify-center">
                  <div
                    className="animate-spin h-10 w-10 border-4 border-blue-500 rounded-full border-t-transparent"></div>
                </div>
              )}
              {!isLoading && !authPassed && !pendingEmailConfirmation && (
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    {i18n.t('individual_pages.receive.verify_email')}
                  </h3>
                  <form
                    className="mt-5 sm:flex sm:items-center"
                    onSubmit={sendAccessCode}
                  >
                    <div className="w-full sm:max-w-xs">
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setAuthErrorMessage('')
                          setAuthEmail(e.target.value);
                        }}
                        className={styles.formInput}
                        placeholder="example@example.com"
                      />
                    </div>
                    <button
                      type="submit"
                      className={`${styles.button} ml-3`}
                    >
                      {i18n.t('action.send')}
                    </button>
                  </form>
                  {authErrorMessage &&
                      <div className="text-red-700">
                        {authErrorMessage}
                      </div>
                  }
                </div>
              )}
              {!isLoading && !authPassed && pendingEmailConfirmation && (
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    {i18n.t('individual_pages.receive.verify_access_code')}
                  </h3>
                  <form
                    className="mt-5 sm:flex sm:items-center"
                    onSubmit={verifyAccessCode}
                  >
                    <div className="w-full sm:max-w-xs">
                      <label htmlFor="email" className="sr-only">
                        Access Code
                      </label>
                      <input
                        type="text"
                        name="accessCode"
                        id="accessCode"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setAuthErrorMessage('')
                          setAccessCode(e.target.value);
                        }}
                        className={styles.formInput}
                        placeholder="1234"
                      />
                    </div>
                    <button
                      type="submit"
                      className={`${styles.button} ml-3`}
                    >
                      {i18n.t('action.send')}
                    </button>
                  </form>
                  {authErrorMessage &&
                      <div className="text-red-700">
                        {authErrorMessage}
                      </div>
                  }
                </div>
              )}
              {!isLoading && authPassed && unavailableMessage.length === 0 && (
                <div>
                  <p>
                    {i18n.t('individual_pages.receive.messageBody', {expireAt: parseUnixtimeToStr(expireAt)})}
                  </p>
                  <button
                    className={`${styles.button} mt-1`}
                    onClick={async () => {
                      await previewResource();
                    }}
                  >
                    {i18n.t('action.download')}
                    <span className="sr-only"></span>
                  </button>
                </div>
              )}
              {!isLoading && authPassed && unavailableMessage.length > 0 && (
                <div>
                  <p>
                    You can access this resource until {expireAt}.
                  </p>
                </div>
              )}
            </div>
          </div>

          <PreviewModal
            openPreviewModal={openPreviewModal}
            setOpenPreviewModal={setOpenPreviewModal}
            url={previewUrl}
          />
        </main>
      </div>
    </>
  );
}
