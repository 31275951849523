import {I18nLocales} from "./types";

export const sc: I18nLocales = {
  action: {
    copy: '复制',
    edit: '编辑',
    cancel: '取消',
    cancel_edit: '取消编辑',
    change: '更改',
    save_change: '保存更改',
    sign_out: '登出',
    sign_in: '登录',
    sign_up: '注册',
    delete: '删除',
    create: '创建',
    upload: '上传',
    download: '下载',
    send: '发送',
    save: '保存',
    add: '添加',
    share: '分享',
    select: '选择',
  },
  noun: {
    email: '电子邮件地址',
    password: '密码',
    password_confirmation: '确认密码',
    organization: '组织',
    organization_setting: '组织设置',
    organization_member_setting: '成员设置',
    access_count: '访问计数',
    resource: '资源',
    memo: '备忘录',
    expiration: '到期时间',
    updated_at: '更新时间',
    created_at: '创建时间',
    accessed_at: '访问时间',
    last_modified_by: '最后修改者',
    access_log: '访问日志',
    timezone: '时区',
    ip: 'IP地址',
    location: '位置',
    user_agent: '用户代理',
    language: '语言',
    admin: '管理员',
    role: '角色',
    user_setting: '用户设置',
    uploaded_resources: '已上传资源',
    sharing_resources: '共享资源',
    access_log_explorer: '访问日志浏览器',
    accessible_emails: '可访问的电子邮件',
    operation_log: '操作日志',
    environment_setting: '环境设置',
    admin_organization_member_setting: '组织成员管理',
    plan: '计划',
    team: '团队',
    current_plan: '当前计划',
    operator: '运营商',
    about_company: '关于公司',
    privacy_policy: '隐私政策',
    terms: '使用条款',
    specified_commercial_transactions: '特定商业交易法披露',
    corporate_site: '企业网站'
  },
  explanation: {
    supported_file_format: '支持的文件格式：.pdf',
    user_setting: '用户设置是指在多个组织中使用相同用户信息时跨组织使用的信息。',
    organization_member: '当前显示的组织内使用的成员设置。',
    organization_setting: '当前显示的组织的设置。',
    team_plan: '所有功能均可用',
  },
  error: {
    invalid_email: '无效的电子邮件格式',
    invalid_password: '无效的密码格式',
    contact_support: '发生了意外错误。如果问题持续，请联系客户支持。',
    invalid_file: '无法上传此文件',
    password_does_not_match: '密码不匹配',
    internal_server_error: '请重试或联系支持',
    invalid_request: '提交内容中存在错误',
    user_not_found: '未找到用户',
    wrong_password: '密码错误',
    too_many_requests: '请求超出限制。请稍后再试。'
  },
  confirmation: {
    are_you_sure_to_delete: '您确定要删除吗？',
    are_you_sure_to_sign_out: '您确定要退出登录吗？',
  },
  notification: {
    success_delete: '删除成功',
    copied_to_clipboard: '已复制到剪贴板'
  },
  state: {
    is_sharing: '正在共享',
  },
  individual_pages: {
    receive: {
      new_resource_arrived: '文件已到达',
      messageBody: "您可以在 {{expireAt}} 之前从此链接下载文件。",
      verify_email: '请输入您的电子邮件地址。',
      verify_access_code: '请输入访问代码。',
    },
    sign_in: {
      forget_password: '如果您忘记了密码',
      not_a_member: '还没有账户吗？',
      reset_password_email_sent: '密码重置邮件已发送。请检查您的邮箱。',
    },
    sign_up: {
      signup_with_confirmation: '同意后注册',
      agree_to_signup1: '点击“同意后注册”，即表示您同意以下内容',
      agree_to_signup2: '・阅读并同意服务条款和隐私政策',
      agree_to_signup3: '・根据需要，我们可能会通过电子邮件发送广告、促销和其他通知',
      successfully_created_user: '用户注册成功完成',
      password_place_holder: '至少包含8个字符，包括大小写字母和符号',
    },
    create_organization: {
      organization_member_name: '组织中的成员名称',
      organization_member_email: '组织中的电子邮件地址',
      organization_member_email_explanation1: '用于在组织内唯一地识别您。',
      organization_member_email_explanation2: '用户的电子邮件地址用于登录和用户自我管理等目的，但在组织内部不使用。',
      create_user: '用户注册',
      create_office: '创建组织',
      go_to_dashboard: '前往仪表板',
      explanation1: '此界面用于创建组织',
      explanation2: '第一步创建的用户可以属于多个组织，并且您将成为此处创建的组织的管理员。',
      explanation3: '对此处创建的组织的成员的邀请可以在仪表板内的组织管理中进行。',
    },
  }
}
