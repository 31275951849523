import {useParams} from "react-router";
import {APIResponse, Organization, ResourceAccessHistory} from "../types";
import {useEffect, useState} from "react";
import i18n from "../i18n";
import {parseDate, parseDateToUnix} from "../utils/date";
import {getUAStr} from "../utils/user_agent";
import {Resource, ResourceWithOneResourceAccessControl} from "../classes/resource";
import {level3ApiEndpoint} from "../utils/apiEndpointUrl";
import {User} from "firebase/auth";
import styles from "../styles/Common.module.css";

export default function SharingResource(
  {
    currentUser,
    currentOrganization
  }: {
    currentUser: User;
    currentOrganization: Organization;
  }) {
  const {resourceId, resourceAccessControlId} = useParams()
  const [resource, setResource] = useState(new ResourceWithOneResourceAccessControl());
  const [resourceAccessControlForm, setResourceAccessControlForm] = useState<ResourceAccessControlForm>({
    memo: {
      value: '',
      error: '',
    },
    expireAtStr: {
      value: '',
      error: '',
    },
    shareDestinationEmails: {
      value: '',
      error: '',
    },
    isSharing: {
      value: "true",
      error: '',
    }
  });
  useEffect(() => {
    if (!resource || !resource.resourceAccessControl) {
      return
    }
    setResourceAccessControlForm({
      memo: {
        value: resource.resourceAccessControl.memo,
        error: ''
      },
      expireAtStr: {
        value: resource.resourceAccessControl.expireAt,
        error: ''
      },
      shareDestinationEmails: {
        value: (resource.resourceAccessControl.shareDestinationEmails || []).map(v => v.email).join(),
        error: ''
      },
      isSharing: {
        value: String(resource.resourceAccessControl.isSharing) as "true" | "false",
        error: ''
      }
    })
  }, [resource])

  const fetchResourceAccessControl = () => {
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(
          `${level3ApiEndpoint()}/resources/${resourceId}/resourceAccessControls/${resourceAccessControlId}?organizationId=${currentOrganization.id}`,
          {
            headers: {'Authorization': idToken},
          }
        )
          .then(async (response) => await response.json())
          .then((response: APIResponse<Resource>) => {
            const resource: Resource = response.content
            const resourceWithOneResourceAccessControl: ResourceWithOneResourceAccessControl = {
              id: resource.id,
              organizationId: resource.organizationId,
              createdBy: resource.createdBy,
              name: resource.name,
              memo: resource.memo,
              resourceAccessControl: resource.resourceAccessControls.filter((r) => r.id === resourceAccessControlId)[0],
              createdAt: resource.createdAt,
              updatedAt: resource.updatedAt,
            }
            setResource(resourceWithOneResourceAccessControl)
          });
      })
      .catch(function (error) {
        // Handle error
      });
  };
  useEffect(() => {
    fetchResourceAccessControl();
  }, []);


  type ResourceAccessControlForm = {
    memo: {
      value: string;
      error: string;
    };
    expireAtStr: {
      value: string;
      error: string;
    };
    shareDestinationEmails: {
      value: string;
      error: string;
    }
    isSharing: {
      value: "true" | "false";
      error: string;
    }
  }

  const [histories, setHistories] = useState<ResourceAccessHistory[]>([])
  const [isEditMode, setIsEditMode] = useState(false)

  useEffect(() => {
    if (!resourceId || !resourceAccessControlId) {
      return
    }

    fetchResourceAccessControl();
  }, [resourceId, resourceAccessControlId])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set('memo', resourceAccessControlForm.memo.value);
    formData.set('isSharing', resourceAccessControlForm.isSharing.value);
    if (resourceAccessControlForm.shareDestinationEmails) {
      formData.set('shareDestinationEmails', resourceAccessControlForm.shareDestinationEmails.value);
    }
    formData.set('expireAt', parseDateToUnix(resourceAccessControlForm.expireAtStr.value).toString());

    currentUser
      .getIdToken()
      .then((idToken) => {
        fetch(
          `${level3ApiEndpoint()}/resources/${resourceId}/resourceAccessControls/${resourceAccessControlId}?organizationId=${currentOrganization.id}`,
          {
            headers: {'Authorization': idToken},
            method: 'PATCH',
            body: formData,
            credentials: 'include',
          }
        ).then((response) => {
          if (response.ok) {
            fetchResourceAccessControl();
            setIsEditMode(false)
          }
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="p-4 sm:p-6 lg:p-8 content-center w-full">
      <div className="flex justify-end">
        {!isEditMode &&
            <button
                className={styles.button}
                onClick={() => setIsEditMode(true)}>
              {i18n.t('action.edit')}
            </button>
        }
        {isEditMode &&
            <div>
                <button
                    className={styles.button}
                    onClick={() => {
                      setIsEditMode(false)
                      if (!resource || !resource.resourceAccessControl) {
                        return
                      }
                      setResourceAccessControlForm({
                        memo: {
                          value: resource.resourceAccessControl.memo,
                          error: '',
                        },
                        expireAtStr: {
                          value: resource.resourceAccessControl.expireAt,
                          error: ''
                        },
                        shareDestinationEmails: {
                          value: (resource.resourceAccessControl.shareDestinationEmails || []).map(v => v.email).join(),
                          error: ''
                        },
                        isSharing: {
                          value: String(resource.resourceAccessControl.isSharing) as "true" | "false",
                          error: ''
                        }
                      })
                    }}
                >
                  {i18n.t('action.cancel_edit')}
                </button>
            </div>
        }
      </div>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">{resource?.name}</h3>
      </div>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={handleSubmit}
        encType={'multipart/form-data'}
        method={'PATCH'}
      >
        <div className="mt-6">
          <dl className="grid grid-cols-1 sm:grid-cols-2">
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n.t('state.is_sharing')}</dt>

              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {isEditMode &&
                    <input
                        id="isSharing"
                        aria-describedby="isSharing"
                        name="isSharing"
                        type="checkbox"
                        className={styles.formInputCheckBox}
                        checked={resourceAccessControlForm?.isSharing.value === "true"}
                        onChange={(event) => {
                          setResourceAccessControlForm(Object.assign({}, resourceAccessControlForm, {
                            isSharing: {
                              value: String(Boolean(event.target.checked)),
                              error: ''
                            }
                          }))
                        }}
                    />
                }
                {!isEditMode &&
                    <input
                        id="isSharingReadOnly"
                        aria-describedby="isSharingReadOnly"
                        name="isSharingReadOnly"
                        type="checkbox"
                        className={styles.formInputCheckBox}
                        disabled={true}
                        checked={!!resource.resourceAccessControl.isSharing}
                    />
                }
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n.t('noun.access_count')}</dt>

              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {0}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n.t('noun.expiration')}</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {isEditMode &&
                    <input
                        id="expireAt"
                        name="expireAt"
                        type="text"
                        className={styles.formInput}
                        value={resourceAccessControlForm.expireAtStr.value}
                        onChange={(event) => {
                          setResourceAccessControlForm(Object.assign({}, resourceAccessControlForm, {
                            expireAtStr: {
                              value: event.target.value,
                              error: ''
                            }
                          }))
                        }}
                    />
                }
                {!isEditMode &&
                    <span>{parseDate(resource.resourceAccessControl.expireAt) || ''}</span>
                }
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n.t('noun.created_at')}</dt>
              <dd
                className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{parseDate(resource.resourceAccessControl.createdAt) || ''}</dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n.t('noun.updated_at')}</dt>
              <dd
                className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{parseDate(resource.resourceAccessControl.updatedAt) || ''}</dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n.t('noun.last_modified_by')}</dt>
              <dd
                className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{resource.resourceAccessControl.lastModifiedBy || ''}</dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n.t('noun.memo')}</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                {isEditMode &&
                    <input
                        id="note"
                        name="note"
                        type="text"
                        className={styles.formInput}
                        value={resourceAccessControlForm.memo.value}
                        onChange={(event) => {
                          setResourceAccessControlForm(Object.assign({}, resourceAccessControlForm, {
                            memo: {
                              value: event.target.value,
                              error: ''
                            }
                          }))
                        }}
                    />
                }
                {!isEditMode &&
                    <span>{resource.resourceAccessControl.memo || ''}</span>
                }
              </dd>
            </div>
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
              <dt
                className="text-sm font-medium leading-6 text-gray-900">{i18n.t('noun.accessible_emails')}</dt>
              <dd className="mt-2 text-sm text-gray-900">
                {!isEditMode &&
                    <div className="flex w-0 flex-1 items-center">
                      {(resource.resourceAccessControl.shareDestinationEmails || []).map(v => v.email).join()}
                    </div>
                }
                {isEditMode &&
                    <div className="flex w-full flex-1 items-center">
                        <input
                            id="shareDestinationEmails"
                            name="shareDestinationEmails"
                            type="text"
                            className={styles.formInput}
                            value={resourceAccessControlForm.shareDestinationEmails.value}
                            onChange={(event) => {
                              setResourceAccessControlForm(Object.assign({}, resourceAccessControlForm, {
                                shareDestinationEmails: {
                                  value: event.target.value,
                                  error: ''
                                }
                              }))
                            }}
                        />
                    </div>
                }
              </dd>
            </div>
            {isEditMode &&
                <div className="mb-5">
                    <button
                        className={styles.button}
                        type="submit"
                    >
                      {i18n.t('action.save_change')}
                    </button>
                </div>
            }
          </dl>
        </div>
      </form>
    </div>
  );
}
