import {useEffect} from 'react';
import {Route, Routes, BrowserRouter, Navigate} from 'react-router-dom';
import ResourceAccessControlList from './pages/ResourceAccessControlList';
import SignIn from './pages/SignIn';
import Receive from './pages/Receive';
import {useState} from 'react';
import {lazy} from 'react';
import {Organization, OrganizationMember} from './types'
import {firebaseAuth} from './firebaseAuth';
import {User} from 'firebase/auth';
import UserSetting from './pages/UserSetting';
import SignUp from './pages/SignUp';
import CreateOrganization from './pages/CreateOrganization';
import OrganizationSetting from './pages/OrganizationSetting';
import OperationLogList from './pages/OperationLog';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import SharingResource from './pages/SharingResource';
import NoAuthLayout from "./components/NoAuthLayout";
import ResetPassword from "./pages/ResetPassword";
import {level2ApiEndpoint} from "./utils/apiEndpointUrl";
import {requestToBackend} from "./utils/rpcLogics";
import I18n from "./i18n";
import OrganizationMemberSetting from "./pages/OrganizationMemberSetting";
import AccessLogExplorer from "./pages/AccessLogExplorer";

const ResourceList = lazy(() => import('./pages/ResourceList'));
const SidebarLayout = lazy(() => import('./components/SidebarLayout'));

function App() {
  const currentUrl = new URL(window.location.href)
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined)
  const [currentOrganization, setCurrentOrganization] = useState<Organization | undefined>(undefined)
  const [organizationOptions, setOrganizationOptions] = useState<Organization[]>([])
  const [isLoading, setLoading] = useState(false)
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  firebaseAuth.onAuthStateChanged(function (user) {
    if (user) {
      setCurrentUser(user)
    }
  });

  setTimeout(() => {
    if (!isPageLoaded) {
      setLoading(false)
      setIsPageLoaded(true)
    }
  }, 1000);

  useEffect(() => {
    if (!currentUser || isLoading) {
      return
    }
    (async () => {
      setLoading(true)
      const [user, error1] = await requestToBackend<User>(currentUser, `${level2ApiEndpoint()}/user`, 'GET')
      if (error1) {
        console.log(I18n.t(`error.${error1}`))
        return
      }
      if (!user) {
        return
      }

      setCurrentUser(user)
      const [organizations, error2] = await requestToBackend<Organization[]>(currentUser, `${level2ApiEndpoint()}/organizations`, 'GET')
      if (error2) {
        console.log(I18n.t(`error.${error2}`))
        setIsPageLoaded(true)
        return
      }
      if (!organizations) {
        setIsPageLoaded(true)
        return
      }
      setOrganizationOptions(organizations)
      const currentOrganizationId = currentUrl.searchParams?.get("organizationId")
      const currentOrganization = organizations.filter((t) => t.id === currentOrganizationId)[0]
      if (currentOrganization) {
        setCurrentOrganization(currentOrganization)
      } else {
        currentUrl.searchParams.set('organizationId', organizations[0].id);
        window.location.href = currentUrl.href
        setCurrentOrganization(organizations[0])
      }
      setIsPageLoaded(true)
    })()
  }, [currentUser])

  if (!isPageLoaded) {
    return (
      <div></div>
    )
  } else if (currentUser && currentOrganization) {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            element={<SidebarLayout organizationOptions={organizationOptions} currentOrganization={currentOrganization}
                                    currentUser={currentUser}/>}>
            {/* <Route path="/" element={<DocumentList currentUser={currentUser} />} /> */}
            <Route path="/receive" element={<Receive/>}/>
            <Route
              path="/tracking"
              element={<ResourceAccessControlList currentOrganization={currentOrganization} currentUser={currentUser}/>}
            />
            <Route
              path="/tracking/:resourceId/resourceAccessControls/:resourceAccessControlId"
              element={<SharingResource currentOrganization={currentOrganization} currentUser={currentUser}/>}
            />
            <Route
              path="/access-log-explorer"
              element={<AccessLogExplorer currentUser={currentUser} currentOrganization={currentOrganization}/>}
            />
            <Route
              path="/resources"
              element={<ResourceList currentUser={currentUser} currentOrganization={currentOrganization}/>}
            />
            <Route
              path="/user-setting"
              element={<UserSetting currentUser={currentUser} currentOrganization={currentOrganization}/>}
            />
            <Route
              path="/organization-member-setting"
              element={<OrganizationMemberSetting currentUser={currentUser} currentOrganization={currentOrganization}/>}
            />
            <Route
              path="/organization-setting"
              element={<OrganizationSetting currentUser={currentUser} currentOrganization={currentOrganization}/>}
            />
            <Route
              path="/operation-log"
              element={<OperationLogList currentUser={currentUser} currentOrganization={currentOrganization}/>}
            />
          </Route>
          <Route
            element={<NoAuthLayout/>}>
            <Route
              path="/create-additional-organization"
              element={<CreateOrganization currentUser={currentUser}/>}
            />
          </Route>
          <Route path="*" element={<Navigate replace to={`/resources?organizationId=${currentOrganization.id}`}/>}/>
        </Routes>
      </BrowserRouter>
    );
    // アカウントを作った直後
  } else if (currentUser && !currentOrganization && !currentUrl.searchParams?.get("organizationId")) {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route
              element={<NoAuthLayout/>}>
              <Route path="/receive" element={<Receive/>}/>
              <Route
                path="/create-initial-organization"
                element={<CreateOrganization currentUser={currentUser}/>}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  } else {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route
              element={<NoAuthLayout/>}>
              <Route
                path="/signin"
                element={<SignIn/>}
              />
              <Route
                path="/signup"
                element={<SignUp/>}
              />
              <Route
                path="/reset-password"
                element={<ResetPassword/>}
              />
              <Route path="/receive" element={<Receive/>}/>
              <Route
                path="/"
                element={<Navigate replace to={'/signin'}/>}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
