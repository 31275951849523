import {I18nLocales} from "./types";

export const en: I18nLocales = {
  action: {
    copy: 'Copy',
    edit: 'Edit',
    cancel: 'Cancel',
    cancel_edit: 'Cancel editing',
    change: 'Change',
    save_change: 'Save Changes',
    sign_out: 'Sign-out',
    sign_in: 'Sign-in',
    sign_up: 'Sign-up',
    delete: 'Delete',
    create: 'Create',
    upload: 'Upload',
    download: 'Download',
    send: 'Send',
    save: 'Save',
    add: 'Add',
    share: 'Share',
    select: 'Select',
  },
  noun: {
    email: 'Email address',
    password: 'Password',
    password_confirmation: 'Password confirmation',
    organization: 'Organization',
    organization_setting: 'Organization settings',
    organization_member_setting: 'Member settings',
    access_count: 'Access count',
    resource: 'Resource',
    memo: 'Memo',
    expiration: 'Expiration',
    updated_at: 'Updated',
    created_at: 'Created',
    accessed_at: 'Accessed',
    last_modified_by: 'Last modified by',
    access_log: 'Access log',
    timezone: 'Timezone',
    ip: 'IP address',
    location: 'Location',
    user_agent: 'User agent',
    language: 'Language',
    admin: 'Administrator',
    role: 'Role',
    user_setting: 'User settings',
    uploaded_resources: 'Uploaded resources',
    sharing_resources: 'Sharing resources',
    access_log_explorer: 'Access log explorer',
    accessible_emails: 'Accessible emails',
    operation_log: 'Operation Log',
    environment_setting: 'Environment settings',
    admin_organization_member_setting: 'Organization member management',
    plan: 'Plan',
    team: 'Team',
    current_plan: 'Current plan',
    operator: 'Operator',
    about_company: 'About the company',
    privacy_policy: 'Privacy policy',
    terms: 'Terms of use',
    specified_commercial_transactions: 'Specified commercial transactions act disclosure',
    corporate_site: 'Corporate site'
  },
  explanation: {
    supported_file_format: 'Supported Extensions: .pdf',
    user_setting: 'User settings are information used across multiple organizations when belonging to them with the same user information.',
    organization_member: 'Settings for members used within the currently displayed organization.',
    organization_setting: 'Settings for the currently displayed organization.',
    team_plan: 'All features are available',
  },
  error: {
    invalid_email: 'Invalid email format',
    invalid_password: 'Invalid password format',
    contact_support: 'An unexpected error has occurred. If the problem persists, please contact customer support.',
    invalid_file: 'Cannot upload this file',
    password_does_not_match: 'Passwords do not match',
    internal_server_error: 'Please try again or contact support',
    invalid_request: 'There is an error in the submitted content',
    user_not_found: 'User not found',
    wrong_password: 'Wrong password',
    too_many_requests: 'Request limit exceeded. Please try again later.'
  },
  confirmation: {
    are_you_sure_to_delete: 'Are you sure you want to delete?',
    are_you_sure_to_sign_out: 'Are you sure you want to sign out?',
  },
  notification: {
    success_delete: 'Deleted successfully',
    copied_to_clipboard: 'Copied to clipboard'
  },
  state: {
    is_sharing: 'Sharing',
  },
  individual_pages: {
    receive: {
      new_resource_arrived: 'You receive a new document!',
      messageBody: "You can download the file from this link until {{expireAt}}.",
      verify_email: 'Please enter your email address.',
      verify_access_code: 'Please enter the access code.',
    },
    sign_in: {
      forget_password: 'If you forgot your password',
      not_a_member: 'Do not have an account yet?',
      reset_password_email_sent: 'Password reset email has been sent. Please check your email.'
    },
    sign_up: {
      signup_with_confirmation: 'Sign up with agreement',
      agree_to_signup1: 'By clicking "Sign up with agreement", you agree to the following',
      agree_to_signup2: '・Read and agree to the Terms of Service and Privacy Policy',
      agree_to_signup3: '・As needed, we may send advertisements, promotions, and other notifications via email',
      successfully_created_user: 'User registration completed successfully',
      password_place_holder: 'At least 8 characters including upper and lower case letters and symbols'
    },
    create_organization: {
      organization_member_name: 'Member name in the organization',
      organization_member_email: 'Email address in the organization',
      organization_member_email_explanation1: 'It is used to uniquely identify you within the organization.',
      organization_member_email_explanation2: "The user's email address is used for purposes such as login and self-management of the user, but it is not used within the organization.",
      create_user: 'User registration',
      create_office: 'Create organization',
      go_to_dashboard: 'Go to Dashboard',
      explanation1: 'This screen is for creating an organization',
      explanation2: 'The user created in Step 1 can belong to multiple organizations, and you will become the administrator of the organization created here.',
      explanation3: 'Invitations to members to the organization created here can be done from the organization management inside the dashboard.',
    },
  }
}
