import {useState} from 'react';
import {firebaseAuth} from '../firebaseAuth';
import {signInWithEmailAndPassword} from "firebase/auth";
import {Grid} from '@mui/material';
import i18n from '../i18n';
import {redirect} from 'react-router-dom';
import StorysetAnnotation from '../components/StorysetAnnotation';
import {Constants} from "../constants";
import I18n from "../i18n";
import {Simulate} from "react-dom/test-utils";
import Notification from '../components/Notification'
import styles from "../styles/Common.module.css"

export default function SignIn() {
  const [signInForm, setSignInForm] = useState(
    {
      email: {
        value: '',
        error: '',
      },
      password: {
        value: '',
        error: '',
      },
    })

  const [openNotification, setOpenNotification] = useState(false);
  const [httpRequestErrorMessage, setHttpRequestErrorMessage] = useState('')

  const isValidSignInForm = (form: typeof signInForm): boolean => {
    let isValid = true
    if (!Constants.regex.email.test(form.email.value)) {
      form = structuredClone(form)
      form.email.error = I18n.t('error.invalid_email')
      setSignInForm(form)
      isValid = false
    }

    return isValid
  }

  const signIn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidSignInForm(signInForm)) {
      return
    }
    signInWithEmailAndPassword(firebaseAuth, signInForm.email.value, signInForm.password.value)
      .then(() => {
      })
      .catch((error) => {
        let error_message
        switch (error.code) {
          case 'auth/user-not-found':
            error_message = I18n.t('error.user_not_found')
            break;
          case 'auth/wrong-password':
            error_message = I18n.t('error.wrong_password')
            break;
          case 'auth/too-many-requests':
            error_message = I18n.t('error.too_many_requests')
            break;
          default:
            error_message = I18n.t('error.contact_support')
            break;
        }
        setHttpRequestErrorMessage(error_message)
        setOpenNotification(true)
      });
  }
  return (
    <Grid container>
      {httpRequestErrorMessage &&
          <Notification
              alertMessage={httpRequestErrorMessage}
              openNotification={openNotification}
              setOpenNotification={setOpenNotification}
          />
      }
      <Grid item md={5}>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 hidden md:block">
          <img src='/images/signin.png'></img>
          <StorysetAnnotation/>
        </div>
      </Grid>
      <Grid item md={7} className="w-full">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 md:py-12 lg:px-8">
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={signIn}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  {i18n.t('noun.email')}
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={signInForm.email.value}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      setSignInForm(Object.assign({}, signInForm, {email: {value: e.target.value, error: ''}}))
                    }}
                    required
                    className={styles.formInput}
                  />
                  <p className="text-red-500 text-xs">{signInForm.email.error}</p>
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    {i18n.t('noun.password')}
                  </label>
                  <div className="text-sm">
                    <a href="/reset-password" className="font-semibold text-gray-600 hover:text-gray-500">
                      {i18n.t('individual_pages.sign_in.forget_password')}
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    value={signInForm.password.value}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      setSignInForm(Object.assign({}, signInForm, {password: {value: e.target.value, error: ''}}))
                    }}
                    className={styles.formInput}
                  />
                  <p className="text-red-500 text-xs">{signInForm.password.error}</p>
                </div>
              </div>

              <div>
                <button
                  className={`${styles.button} w-full`}
                  type="submit"
                >
                  {i18n.t('action.sign_in')}
                </button>
              </div>
            </form>

            <div className="mt-10 text-center text-sm text-gray-500">
              {i18n.t('individual_pages.sign_in.not_a_member')} <br/>
              <a href="/signup" className="font-semibold leading-6">
                {i18n.t('action.sign_up')}
              </a>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
