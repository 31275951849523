import {useState} from 'react';
import {firebaseAuth} from '../firebaseAuth';
import {User, signInWithEmailAndPassword} from "firebase/auth";
import i18n from '../i18n';
import {Constants} from "../constants";
import I18n from "../i18n";
import Notification from "../components/Notification";
import styles from "../styles/Common.module.css";
import {level2ApiEndpoint} from "../utils/apiEndpointUrl";
import {requestToBackend} from "../utils/rpcLogics";
import {Organization} from "../types";

export default function CreateOrganization({currentUser}: {
  currentUser: User
}) {
  const [httpRequestErrorMessage, setHttpRequestErrorMessage] = useState('')
  const [openNotification, setOpenNotification] = useState(false);
  const [createOrganizationForm, setCreateOrganizationForm] = useState(
    {
      organizationName: {
        value: '',
        error: '',
      },
      organizationMemberEmail: {
        value: '',
        error: '',
      },
    })
  const isValidCreateOrganization = (form: typeof createOrganizationForm): boolean => {
    let isValid = true
    if (!Constants.regex.email.test(form.organizationMemberEmail.value)) {
      form = structuredClone(form)
      form.organizationMemberEmail.error = I18n.t('error.invalid_email')
      setCreateOrganizationForm(form)
      isValid = false
    }

    return isValid
  }
  const createOrganization = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidCreateOrganization(createOrganizationForm)) {
      return
    }
    const formData = new FormData();
    formData.append('organizationName', createOrganizationForm.organizationName.value);
    formData.append('email', createOrganizationForm.organizationMemberEmail.value);
    const [organization, error] = await requestToBackend<Organization>(currentUser, `${level2ApiEndpoint()}/organizations`, 'POST', formData)
    if (error) {
      setOpenNotification(true)
      setHttpRequestErrorMessage(error)
    } else {
      if (organization) {
        window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/resources?organizationId=${organization.id}`
      }
    }
  }
  return (
    <>
      {httpRequestErrorMessage &&
          <Notification
              alertMessage={httpRequestErrorMessage}
              openNotification={openNotification}
              setOpenNotification={setOpenNotification}
          />
      }
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <nav aria-label="Progress">
          <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
            <li className="md:flex-1">
              <div
                className="group flex flex-col border-l-4 border-blue-700 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                <span className="text-sm font-medium text-blue-700 group-hover:text-indigo-800">Step 1</span>
                <span
                  className="text-sm font-medium">{i18n.t('individual_pages.create_organization.create_user')}</span>
              </div>
            </li>
            <li className="md:flex-1">
              <div
                className="flex flex-col border-l-4 border-blue-700 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                <span className="text-sm font-medium text-blue-700">Step 2</span>
                <span
                  className="text-sm font-medium">{i18n.t('individual_pages.create_organization.create_office')}</span>
              </div>
            </li>
            <li className="md:flex-1">
              <div
                className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">Complete 🎉</span>
                <span
                  className="text-sm font-medium">{i18n.t('individual_pages.create_organization.go_to_dashboard')}</span>
              </div>
            </li>
          </ol>
        </nav>
        <div className="rounded-md bg-blue-50 p-4 mt-10">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                      clip-rule="evenodd"/>
              </svg>
            </div>
            <div className="ml-3">
              <h3
                className="text-sm font-medium text-blue-700">{I18n.t('individual_pages.create_organization.explanation1')}</h3>
              <div className="mt-2 text-sm text-blue-700">
                <p>{I18n.t('individual_pages.create_organization.explanation2')}</p>
                <p>{I18n.t('individual_pages.create_organization.explanation3')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={createOrganization}>
            <div>
              <label htmlFor="organizationName" className="block text-base font-medium leading-6 text-gray-900">
                {i18n.t('noun.organization')}
              </label>
              <div className="mt-2">
                <input
                  id="organizationName"
                  name="organizationName"
                  type="text"
                  value={createOrganizationForm.organizationName.value}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    setCreateOrganizationForm(Object.assign({}, createOrganizationForm, {
                      organizationName: {
                        value: e.target.value,
                        error: ''
                      }
                    }))
                  }}
                  required
                  className={styles.formInput}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="email" className="block text-base font-medium leading-6 text-gray-900">
                  {i18n.t('individual_pages.create_organization.organization_member_email')}
                </label>
              </div>
              <p
                className="text-sm text-gray-600">
                {i18n.t('individual_pages.create_organization.organization_member_email_explanation1')}
              </p>
              <p
                className="text-sm text-gray-600">
                {i18n.t('individual_pages.create_organization.organization_member_email_explanation2')}
              </p>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={createOrganizationForm.organizationMemberEmail.value}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    setCreateOrganizationForm(Object.assign({}, createOrganizationForm, {
                      organizationMemberEmail: {
                        value: e.target.value,
                        error: ''
                      }
                    }))
                  }}
                  className={styles.formInput}
                />
                <p className="text-red-500 text-xs">{createOrganizationForm.organizationMemberEmail.error}</p>
              </div>
            </div>

            <div>
              <button
                className={`${styles.button} w-full`}
                type="submit"
              >
                {i18n.t('action.post')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
